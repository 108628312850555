<template>
  <Toast/>

  <Button label="New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew"/>

  <Dialog v-model:visible="itemDialog" :style="{width: '450px'}" :header="formHeader" :modal="true" class="p-fluid">
    <!-- Name -->
    <div class="field">
      <label for="name">Name*</label>
      <InputText
        id="name" v-model.trim="v$.item.name.$model" required="true" autofocus
        :class="{'p-invalid': v$.item.name.$invalid && submitted}"
      />
      <small
        v-if="(v$.item.name.$invalid && submitted) || v$.item.name.$pending.$response"
        class="p-error">{{ v$.item.name.required.$message.replace('Value', 'Name') }}
      </small>
    </div>

    <!-- Key -->
    <div class="field">
      <label for="key">Key*</label>
      <InputText
        id="key" v-model.trim="v$.item.key.$model" required="true" autofocus
        :class="{'p-invalid': v$.item.key.$invalid && submitted}"
      />
      <small
        v-if="(v$.item.key.$invalid && submitted) || v$.item.key.$pending.$response"
        class="p-error">{{ v$.item.key.required.$message.replace('Value', 'Key') }}
      </small>
    </div>

    <!-- Key -->
    <div class="field">
      <label for="key">User name*</label>
      <InputText
        id="username" v-model.trim="v$.item.username.$model" required="true" autofocus
        :class="{'p-invalid': v$.item.username.$invalid && submitted}"
      />
      <small
        v-if="(v$.item.username.$invalid && submitted) || v$.item.username.$pending.$response"
        class="p-error">{{ v$.item.username.required.$message.replace('Value', 'User name') }}
      </small>
    </div>

    <!-- Url -->
    <div class="field">
      <label for="key">Url*</label>
      <InputText
        id="url" v-model.trim="v$.item.url.$model" required="true" autofocus
        :class="{'p-invalid': v$.item.url.$invalid && submitted}"
      />
      <span v-if="v$.item.url.$error && submitted">
        <span id="url-error" v-for="(error, index) of v$.item.url.$errors" :key="index">
          <small class="p-error">{{ error.$message.replace('Value', 'Url') }}</small>
        </span>
      </span>
      <small
        v-else-if="(v$.item.url.$invalid && submitted) || v$.item.url.$pending.$response"
        class="p-error">{{ v$.item.url.required.$message.replace('Value', 'Url') }}
      </small>
    </div>

    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
      <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveApiProvider(!v$.$invalid)" />
    </template>
  </Dialog>
</template>

<script>
import { required, url } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import ApiProviderResource from '@http/ApiProviderResource';
import toastParamBuilder from "@/builders/ToastParamBuilder";

export default {
  setup: () => ({v$: useVuelidate()}),

  emits: ["hideDialog"],

  data() {
    return {
      itemDialog: false,
      formHeader: '',
      item: {
        name: '',
        key: '',
        username: '',
        url: '',
      },
      filters: {},
      submitted: false,
    }
  },

  props: {
    isSelectedEditForm: Boolean,
    selectedId: Number,
  },

  itemResource: null,

  created() {
    this.itemResource = new ApiProviderResource();
  },

  methods: {
    openNew() {
      // this.item = {};
      this.formHeader = 'Create Api Provider';
      this.submitted = false;
      this.itemDialog = true;
    },

    hideDialog() {
      this.itemDialog = false;
      this.submitted = false;
    },

    saveApiProvider(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      if (this.item.id) {
        this.$store.dispatch('ApiProvider/updateApiProvider', { id: this.item.id, payload: this.item })
          .then(response => {
            this.$toast.add(toastParamBuilder.success(response));
            this.closeDialog();
          })
          .catch(error => {
            this.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
          });
      } else {
        this.$store.dispatch('ApiProvider/createApiProvider', this.item)
          .then(response => {
            this.$toast.add(toastParamBuilder.success(response));
            this.closeDialog();
          })
          .catch(error => {
            this.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
          });
      }
    },

    loadEdit(id) {
      this.formHeader = 'Update Api Provider';
      this.itemResource.getApiProviderById(id)
        .then(data => {
          this.item = data;
          this.submitted = false;
          this.itemDialog = true;
        });
    },

    closeDialog() {
      this.itemDialog = false;
      this.item = {};
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          _r: Date.now(),
        }
      });
    }
  },

  watch: {
    isSelectedEditForm(value) {
      if (value) {
        this.loadEdit(this.selectedId);
      }
    },

    itemDialog(value) {
      if (! value) {
        this.$emit('hideDialog');
      }
    },
  },

  validations() {
    return {
      item: {
        name: { required },
        key: { required },
        username: { required },
        url: { required, url },
      }
    }
  },
}
</script>
